import { useState, useEffect } from 'react'
import { withErrorBoundary, SwitchField } from 'shared'

const PrivacySettings = ({ person }) => {
  const [privacySettings, setPrivacySettings] = useState(() => ({
    user_id: person?.id ?? null,
    is_searchable: person?.is_searchable ?? false,
    shows_phone: person?.shows_phone ?? false,
    shows_email: person?.shows_email ?? false,
    shows_address: person?.shows_address ?? false,
    shows_bio: person?.shows_bio ?? false
  }));
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (person) {
      setPrivacySettings({
        user_id: person?.id ?? null,
        is_searchable: person?.is_searchable ?? false,
        shows_phone: person?.shows_phone ?? false,
        shows_email: person?.shows_email ?? false,
        shows_address: person?.shows_address ?? false,
        shows_bio: person?.shows_bio ?? false
      });
      setLoading(false);
    }
  }, [person]);

  const inputChanged = (e) => {
    if (!e || !e.target) return;

    const { name, checked } = e.target;

    setPrivacySettings(prev => ({
      ...prev,
      [name]: checked
    }));
  };

  const submit = async () => {
    setSaving(true);
    try {
      await Backend.put("/profile", { user: privacySettings });
      console.log("Privacy settings saved successfully!");
    } catch (error) {
      console.error("Error saving privacy settings:", error);
    }
    setSaving(false);
  };

  if (loading) {
    return <div className="text-center mt-4">Loading privacy settings...</div>;
  }

  return (
    <div className="PrivacyPage">
      <h3 className="text-center mb-3" style={{ fontFamily: 'Roboto, sans-serif', fontWeight: '300', fontSize: '18px' }}>
        Privacy Settings
      </h3>
      <SwitchField title="Visible and Searchable Profile" id="privacy-profile" name="is_searchable"  model={privacySettings} onChange={inputChanged} />
      <SwitchField title="Phone Number Visible" id="privacy-phone" name="shows_phone"  model={privacySettings} onChange={inputChanged} />
      <SwitchField title="Email Address Visible" id="privacy-email" name="shows_email"  model={privacySettings} onChange={inputChanged} />
      <SwitchField title="Street Address Visible" id="privacy-address" name="shows_address"  model={privacySettings} onChange={inputChanged} />
      <SwitchField title="Bio Visible" id="privacy-bio" name="shows_bio"  model={privacySettings} onChange={inputChanged} />
      <div className="mt-3 text-center">
        <button type="button" className="smart-button event-information-detail" style={{ minWidth: '150px' }} onClick={submit} disabled={saving}>
          {saving ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default withErrorBoundary(PrivacySettings);
