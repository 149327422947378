import { MissingImage } from 'customizations'
import { Modal, withErrorBoundary, Formatter, connected } from 'shared'
import { SmartButton } from 'ui/smart-buttons'
import { Route, Switch, Redirect } from 'react-router-dom'
import { pluralize } from 'shared/util'
import SmartButtonApi  from 'api/smart-button-api'
import PrivacySettings from './PrivacySettings'

class PersonModal extends Component {
  render() {
    let { person, base, smartButtons, reloadSmartButtons, showPrivacySettings } = this.props

    return (
      <div className="PersonModal custom-modal">
        <div className="modal-head-without-heigth">
         <table className="person-table table">
          <tbody>
              <tr
                key={person.uid}
                className="person-table-row"
                style={{ borderBottom: '1px solid #ddd' }}
              >
                <td className="person-avatar-cell">
                  {person.picture && (
                    <Link
                      to={`people/${person.uid}`}
                      className="plain-link"
                    >
                      <img
                        src={person.picture}
                        className="person-avatar rounded-circle"
                      />
                    </Link>
                  )}
                </td>
                <td className="event-information">
                  {person.first_name} {person.last_name}
                </td>
              </tr>
            </tbody>
          </table>
          <Modal.CloseButton />
        </div>
        <div className="modal-body d-block">
          <div>
            <div>
              <h4 className="event-information">Upcoming Events Attending:</h4>
              {person.event_details && person.event_details.length > 0 ? (
                <div className="event-information-detail">
                  <ul>
                    {person.event_details.map((event, index) => (
                      <li key={index}>{event}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p className="event-information-detail">No upcoming events.</p>
              )}

              <h4 className="event-information">Clubs and Groups:</h4>
              {person.clubs_details && person.clubs_details.length > 0 ? (
                <div className="event-information-detail">
                  <ul>
                    {person.clubs_details.map((club, index) => (
                      <li key={index}>{club}</li>
                    ))}
                  </ul>
                </div>
              ) : (
                <p>No clubs joined or interested.</p>
              )}
              <div style={{ borderBottom: '1px solid #ddd', margin: '10px 0' }}></div>
              {person.phone && <p className="event-information">Phone: {person.phone}</p>}
              {person.email && <p className="event-information">Email: {person.email}</p>}
              {person.address && <p className="event-information">Address: {person.address}</p>}
              {person.bio && (
                <p className="person-bio-block">
                  <p className="event-information">Bio:</p>{' '}
                  <span className="event-information-detail" dangerouslySetInnerHTML={{ __html: Formatter.simpleFormat(person.bio) }}></span>
                </p>
               )}
              {person.followers !== undefined && person.followers !== null && (
                <p className="event-information">Follower Count: {pluralize(person.followers, "follower", "followers")}</p>
              )}

              <div style={{ borderBottom: '1px solid #ddd', margin: '20px 0' }}></div>
              {showPrivacySettings && (
                <PrivacySettings person={person} />
              )}
            </div>
            <div className="entity-smart-buttons smart-buttons text-center">
              {smartButtons && smartButtons.map(smartButton =>
                <SmartButton {...{smartButton, reloadSmartButtons, base}} key={smartButton.key} returnPath={application.path} />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PersonModal.requestSmartButtons = (success) => {
  return SmartButtonApi.forUser(success)
}

export default SmartButton.containerComponent(null, withErrorBoundary(connected(PersonModal)), { Source: PersonModal })
