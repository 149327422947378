import { Api } from 'base'
import Park from './park'

class HighlightApi extends Api {
  collectionName = 'highlights'

  async primary_highlights() {
    let json = await Backend.get('/highlights')
    if (json.total > 0) {
      return this.adjustHighlights(json.highlights)
    }
    return []
  }

  async secondary_highlights() {
    let json = await Backend.get('/secondary_highlights')
    let models = json.highlights.map(r =>
      new Park(r.place)
    )
    return models
  }

  adjustHighlights(highlights) {
    highlights = highlights.map(r => ({
      ...r,
      place: new Park(r.place)
    }))

    const length = highlights.length
    while (highlights.length < 4) {
      highlights.push(highlights[0])
    }

    return highlights
  }
}

export default new HighlightApi
