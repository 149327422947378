import React from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

const containerStyle = {
  width: '100%',
  height: '400px',
}

const CustomGoogleMap = ({ defaultCenter, defaultZoom, children }) => {
  const center = defaultCenter || { lat: 37.8, lng: -122.4 }
  const zoom = defaultZoom || 4

  return (
    <LoadScript googleMapsApiKey={App.googleApiKey}>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        options={{ disableDefaultUI: true }}
      >
        {children}
      </GoogleMap>
    </LoadScript>
  )
}

CustomGoogleMap.Marker = Marker
export default CustomGoogleMap
