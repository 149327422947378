import { MissingImage } from 'customizations'
import { Route, Switch, Redirect } from 'react-router-dom'
import { Modal, withErrorBoundary, GoogleStaticMap, Formatter, NoResults } from 'shared'
import { Model, ParkApi } from 'api';
import { SmartButton, SmartButtonLink } from 'ui/smart-buttons'
import { EventCardMini } from 'ui/events'
import ParkReservationBlock from './ParkReservationBlock';
import ParkReservationSlots from './ParkReservationSlots'
import ParkImageSlider from "./ParkImageSlider"

class ParkModal extends Component {
  state = { timeSlots: null }

  componentDidMount() {
    // if (application.canNestChat) App.state.setObject({ base: application.path })
    this.loadData({})
  }

  async loadData(prevProps) {
    let currDate = moment(application.todayDateString)
    this.setState({ date: currDate, timeSlots: null })
    await ParkApi.loadTimeSlotsForDate(this.props.park, currDate, null).then(data =>
        this.setState({ timeSlots: data }))
  }

  render() {
    return (
      <div className="ParkModal custom-modal">
        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    let { park, smartButtons, reloadSmartButtons, base = park.baseCollectionName, subroute } = this.props
    let [ date, time ] = Model.extractSubrouteArgs(subroute)
    let { timeSlots } = this.state
    smartButtons = smartButtons && smartButtons.filter(b => !(b.kind == 'place-reservation' && b.place_id == park.id))

    return (
      <div className="modal-body">
        <button className="close-modal" onClick={this.props.onClose}>&times;</button>
        <div className="left-pane">
          <div className="card park-title-card">
            <h3 className="park-name">{park.name}</h3>
            <ParkImageSlider images={park.image_urls} fallbackImage={MissingImage} />
          </div>

          <div className="card park-info">
            <h3 className="section-title">
              {park.isFeatureEnabled('map') && park.address && (
                <>
                  <a href={park.address_map_url} className="text-ligher event-information-light" target="_blank">{park.address}</a>
                  <hr />
                </>
              )}
              { park.isPark &&
                <div className="entity-schedule">
                  <div className="es-state event-information">
                    { park.isFeatureEnabled('opening') && park.opening_status_str }
                  </div>
                  <ul className="es-list">
                    {park.isFeatureEnabled('schedule') && park.open_periods && Object.keys(park.open_periods).map(period =>
                      <li key={period}>
                        <span className="es-weekday event-information-detail">{period}</span>
                        <span className="es-hours event-information-light">{park.opening_hours_for(period)}</span>
                      </li>
                    )}
                  </ul>
                  <hr />
                </div>
              }
            </h3>
            <div className="park-description event-information-detail">
              <div dangerouslySetInnerHTML={{__html: Formatter.simpleFormat(park.description)}}></div>
            </div>
          </div>
        </div>

        {(
          (smartButtons?.length > 0 && (!park.isRetail || (park.isRetail && park.isJoinRequired)) && time == null) || park.isFeatureEnabled('map') ||
          (park.isPark && (park.is_reservable || park.reservations?.length > 0 || park.embedded_url || park.embedded_html)) || (User.current.guest && (!User.current.regular && park.isRetail && park.isJoinRequired))
        ) && (
        <div className="card right-pane right-card">
          {
            smartButtons?.length > 0 && (!park.isRetail || (park.isRetail && park.isJoinRequired)) && time == null &&
              <div className="card park-buttons">
                <div className="smart-buttons">
                  {smartButtons.map(smartButton =>
                    <SmartButton {...{smartButton, reloadSmartButtons, base}} object={park} key={smartButton.key} returnPath={application.path} />
                  )}
                </div>
              </div>
          }
          { park.isFeatureEnabled('map') &&
            <div className="card park-map">
              <div className="entity-map">
                <GoogleStaticMap defaultZoom={15} defaultCenter={park.coords} address={park.address} />
              </div>
            </div>
          }
          { park.isPark && (park.is_reservable || (Array.isArray(park.reservations) && park.reservations.length > 0) || !!park.embedded_url || !!park.embedded_html) && (
            <div className="card park-service-section">
              <div className="park-calendar-section">
                {
                  (park.embedded_url || park.embedded_html) &&
                    <iframe id="park-embedded-page" className="park-iframe" src={park.embedded_url} srcDoc={park.embedded_html}></iframe>
                }
                {
                  !User.current.isGuestExperience &&
                    <>
                      {park.reservations && time == null &&
                        <ParkReservationsListing reservations={park.reservations} />
                      }
                      {park.isPark && park.is_reservable &&
                        <ParkReservationBlock park={park} reloadSmartButtons={reloadSmartButtons} base={base} subroute={subroute} />
                      }
                      {
                        time == null &&
                          <div className="entity-children">
                            {park.events.map(event =>
                              <EventCardMini event={event} base={`${base}/${park.uid}`} key={event.uid} />
                            )}
                          </div>
                      }
                    </>
                }
              </div>
            </div>
          )}
          {
            User.current.guest &&
              <>
                {!User.current.regular && park.isRetail && park.isJoinRequired &&
                  <div className="card park-buttons">
                    <div class="smart-buttons">
                      <SmartButtonLink text="Follow" className="smart-button btn" to={`/marketplace-signup/?retail_id=${park.id}`} />
                    </div>
                  </div>
                }
              </>
          }
        </div>
        )}
      </div>
    );
  }

  renderItem(slot) {
    let { time, reservation, available, militaryTime } = slot
    let options = { }
    options = {
      text: `${ reservation ? `Reserved: ` : `Open:` } ${slot.desc}`,
      className: classNames('mb-2 btn-sm park-reservation-btn', { gray: reservation ? true : false }),
      key: time,
    }

    if (User.current && User.current.guest) {
      options.click = () => {
        App.state.setObjectSilent({ afterSignupPath: App.path })
        App.go(`${this.props.base}/guest-signup`)
      }
    }
      let url = ``
      return <SmartButtonLink to={url} {...options} click={null} />
  }
}

const ParkReservationsListing = ({reservations}) =>
  reservations.map(reservation =>
    <div key={reservation.key}
      className={classNames('park-reservation-status', { 'approved': reservation.isApproved, 'pending': reservation.isPending })}
    >
      {Formatter.dateUS(reservation.date)} {Formatter.hhmmToAmpm(reservation.time)}–{Formatter.hhmmToAmpm(reservation.end_time)}{' '}
      {reservation.isPending ? 'Reservation submitted' : 'Reserved'}
      {reservation.service && <span>{': '}{reservation.service.name}</span>}
    </div>
  )

export default SmartButton.containerComponent('park', withErrorBoundary(ParkModal))
