import LikeImage from 'images/like.png';
import LikeEmptyImage from 'images/like-empty.png';
import PublishImage from 'images/publish.png';
import PublishEmptyImage from 'images/publish-empty.png';
import { Modal, withErrorBoundary, GoogleStaticMap, Formatter } from 'shared';
import { SmartButton } from 'ui/smart-buttons';
import { eventHours, eventDate, eventDateTitle } from './EventHelper';
import OccurrenceRow from './OccurrenceRow';
import EventReservationBlock from './EventReservationBlock';
import { MissingImage } from 'customizations';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ParkImageSlider from '../parks/ParkImageSlider';

class EventModal extends Component {
  componentDidMount() {
    let { event } = this.props;
    if (event && event.isEvent) event.track();
    // if (application.canNestChat) App.state.setObject({ base: application.path })
  }

  render() {
    return (
      <div className="EventModal custom-modal">
        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    let { event, smartButtons, reloadSmartButtons, base, subroute } = this.props;
    return (
      <div className="modal-body">
        <button className="close-modal" onClick={this.props.onClose}>&times;</button>
        <div className="left-pane">
          <div className="card event-title-card">
            <div className="titlebar-actions">
              {event.isEventlike && App.user.regular && (
                <>
                  <span className="modal-titlebar-action" onClick={() => event.like()}>
                    <img src={event.isLiked ? LikeEmptyImage : LikeImage} />
                    <span className="text event-information-light">{event.view_count}</span>
                  </span>
                  {event.isRegular && (
                    <span className="modal-titlebar-action follower-action" onClick={() => event.publish()}>
                      <img src={event.isPublished ? PublishEmptyImage : PublishImage} />
                      <span className="follower-text event-information-light">Push to Followers</span>
                    </span>
                  )}
                </>
              )}
            </div>
            <h3 className="event-name">{event.name}</h3>
            <ParkImageSlider images={event.image_urls} fallbackImage={MissingImage} />
          </div>

          <div className="card event-info">
            <h2 className="section-title event-information mb-0">Event Information</h2>
            <h3 className="section-title event-information">{event.sub_title}</h3>
            <div className="event-description event-information-detail">
              {event.note && <p>{event.note}</p>}
              <div dangerouslySetInnerHTML={{ __html: event.description }}></div>
            </div>
          </div>
        </div>

        <div className="card right-pane right-card">
          <div className="card event-date-map">
            <div className="event-date-section">
              <FontAwesomeIcon icon={faCalendarAlt} className="calendar-icon" />
              <div className="event-entity-date event-information-detail" title={eventDateTitle(event)}>{eventDate(event, 'longest')}</div>
              <div className="event-entity-time event-information-light" title={eventDateTitle(event)}>{eventHours(event)}</div>
            </div>
          </div>
          {((subroute || '').startsWith('reserve') || (this.renderRightPane() && React.isValidElement(this.renderRightPane()) &&
            React.Children.toArray(this.renderRightPane().props.children).some(child =>
              React.isValidElement(child) ? React.Children.toArray(child.props.children).length > 0 : (typeof child === 'string' && child.trim() !== '')
            )
          )) && (
          <div className="card event-buttons">
            <div className="right-pane">
              {
                (subroute || '').startsWith('reserve') ?
                  <EventReservationBlock {...{ event, base, subroute, reloadSmartButtons }} /> :
                  this.renderRightPane()
              }
            </div>
          </div>
          )}
          {event.showMap && (
            <div className="card event-map">
              <div className="entity-map">
                <GoogleStaticMap defaultZoom={15} defaultCenter={event.coords} address={event.address} />
              </div>
              <div className="left-pane-text-content">
                <div className="entity-address event-information-light">
                  <a href={event.address_map_url} className="text-ligher" target="_blank">{event.address}</a>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderRightPane() {
    let { event } = this.props
    return (
      <div className='event-base-info'>
        {event.isEnrollmentPending &&
          <div className="alert alert-warning text-center">
            Enrollment request submitted
          </div>
        }
        {event.occurrences && this.renderOccurrences()}
        {(event.isEventlike || event.isParkReservation) && this.renderSmartButtons()}
      </div>
    )
  }

  renderOccurrences() {
    let { event, base } = this.props;
    return (
      <div className="event-occurrence-list">
        {event.hasOccurrences ? (
          <table className="event-occurrence-table">
            <tbody>
              {event.occurrenceObjects.map(occurrence => (
                <OccurrenceRow key={occurrence.id} {...{ event, occurrence, base }} />
              ))}
            </tbody>
          </table>
        ) : (
          <div>Will not occur any time soon.</div>
        )}
      </div>
    );
  }

  renderSmartButtons() {
    let { event, smartButtons, reloadSmartButtons, base } = this.props;
    return (
      <div className="smart-buttons event-smart-buttons">
        {smartButtons &&
          smartButtons.map(smartButton => (
            <SmartButton {...{ smartButton, reloadSmartButtons, base }} object={event} key={smartButton.key} returnPath={application.path} />
          ))}
      </div>
    );
  }
}

export default SmartButton.containerComponent('event', withErrorBoundary(EventModal));
