import React from "react";
import { LogoImage, LogoImageRight } from "customizations";
import { User } from "api";

const Footer = () => {
  const formatUrl = (url) => {
    if (!url) return null;
    return url.startsWith("http://") || url.startsWith("https://") ? url : `https://${url}`;
  };

  const renderLogo = () => {
    const logoUrl = User?.current?.logoUrl;

    if (!logoUrl || logoUrl === "null") {
      return (
        <a href="/">
          <img className="main-logo" src={LogoImage} alt="Company Logo" />
        </a>
      );
    }

    return (
      <a href="/">
        <img className="main-logo" src={logoUrl} alt="Company Logo" />
      </a>
    );
  };

  return (
    <footer className="bg-white py-4 border-top mt-5">
      <div className="container text-center">
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex align-items-center mb-3">
            <span className="navbar-text sidebar d-flex align-items-center">
              <img 
                width="36" 
                height="36" 
                src={User.current && !User.current.isGuestExperience ? (User.current.picture || LogoImageRight) : LogoImageRight} 
                className={User.current && !User.current.isGuestExperience ? 'profile-image' : 'app-icon'} 
              />
            </span>
          </div>
          <p className="text-muted text-center" style={{ wordBreak: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
            {User.homeFeedFooter}
          </p>
          <div className="d-flex mt-3" style={{ gap: "3.5rem" }}>
            {User.homeFeedFacebook && (
              <a href={formatUrl(User.homeFeedFacebook)} className="text-muted fs-5" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook-f"></i>
              </a>
            )}
            {User.homeFeedInstagram && (
              <a href={formatUrl(User.homeFeedInstagram)} className="text-muted fs-5" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
              </a>
            )}
            {User.homeFeedTwitter && (
              <a href={formatUrl(User.homeFeedTwitter)} className="text-muted fs-5" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter"></i>
              </a>
            )}
            {User.homeFeedYoutube && (
              <a href={formatUrl(User.homeFeedYoutube)} className="text-muted fs-5" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube"></i>
              </a>
            )}
          </div>
        </div>
        <div className="border-top mt-3 pt-3">
          <p className="text-muted text-center mb-0">
            © 2025{" "}
            <a
              href="https://www.alosant.com"
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              Alosant
            </a>{" "}
            All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
