import Modal from 'shared/modal'
import ParkModal         from './parks/ParkModal'
import EventModal        from './events/EventModal'
import ChatModal         from './chat/ChatModal'
import PaymentModal      from './payment-modal'
import PersonModal       from './people/PersonModal'
import GuestSignupModal  from './public/GuestSignupModal'
import ImageGalleryModal from './smart-buttons/ImageGalleryModal'
import { Event, Park, Club, Person, ImageGallery } from 'api'
import { absolutizePath } from 'shared/util'

export default function PolymorphicModal({ model, returnUrl, subroute }) {
  if (model == null) return null

  returnUrl = absolutizePath(returnUrl)

  if (model == 'paymentButton')
    return <PaymentModal base={returnUrl} />

  if (model == 'chat') return (
    <Modal title='Chat' opened onClose={returnUrl}>
      <ChatModal base={returnUrl} />
    </Modal>
  )

  if (model == 'guest-signup')
    return <Modal title='Signup' opened onClose={returnUrl}>
      <GuestSignupModal base={returnUrl} />
    </Modal>

  if (model instanceof Event ||
      model instanceof Park ||
      model instanceof Person ||
      model instanceof ImageGallery)
    return (
      <Modal title={model.name} opened onClose={returnUrl}>
        {
          model instanceof Event ? <EventModal event={model} base={returnUrl} subroute={subroute} onClose={() => window.history.back()}/> :
          model instanceof Park || model instanceof Club ? <ParkModal park={model} base={returnUrl} subroute={subroute} onClose={() => window.history.back()}/> :
          model instanceof Person ? <PersonModal person={model} base={returnUrl} subroute={subroute} /> :
          model instanceof ImageGallery ? <ImageGalleryModal gallery={model} base={returnUrl} subroute={subroute} /> : null
        }
      </Modal>
    )
  return null
}
