import { Api } from 'base'
import Announcement from './announcement'

class AnnouncementApi extends Api
  collectionName: 'announcements'

  index: ->
    response = await Backend.get '/announcements'
    _.map response.announcements, (r) => new Announcement(r)

  markAsRead: (announcementId) ->
    response = await Backend.post "/announcements/#{announcementId}/mark_as_read"
    new Announcement(response.announcement)

export default new AnnouncementApi
