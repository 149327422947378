import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <div className="privacy-content">{User.homeFeedPrivacyPolicy}</div>
    </div>
  )
}

export default PrivacyPolicy
