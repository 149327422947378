import { GradientedScroller, connected, withErrorBoundary, NoResults } from 'shared'
import { absolutizePath } from 'shared/util'
import { TopLevelPage, ModelGrid } from 'ui'
import EventCard from 'ui/events/EventCard'
import ParkCard from 'ui/parks/ParkCard'
import { EventApi, AnnouncementApi, Park, ParkApi, Menu, User } from 'api'
import { addDays, format, startOfMonth, endOfMonth } from 'date-fns'
import { useEffect, useState } from 'react'
import Announcements from '../events/Announcements'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'

calendarGlobal = []
intervalId = null

CalendarView = ({match}) ->
  feedItemId = match.params.id
  [calendar, setCalendar] = useState([
    {
      startDate: startOfMonth(new Date()),
      endDate: endOfMonth(new Date()),
      key: 'selection'
    }
  ])
  [events, setEvents] = useState([])
  [openAvailable, setOpenAvailable] = useState([])
  [showReservations, setShowReservations] = useState(false)
  [selectedPlaces, setSelectedPlaces] = useState([])
  [reservablePlaces, setReservablePlaces] = useState([])
  [selectedDate, setSelectedDate] = useState(new Date())
  [reservableDates, setReservableDates] = useState([])
  announcements = AnnouncementApi.kick('index')

  parseDate = (dateTime) ->
    format(dateTime, "yyyy'-'MM'-'dd")

  dateParams = (calendar) ->
    {
      start_date: parseDate(calendar.startDate),
      end_date: parseDate(calendar.endDate)
    }

  fetchReservableDates = () ->
    start = new Date()
    end = endOfMonth(new Date())
    dates = []
    
    for i in [0...((end - start) / (1000 * 60 * 60 * 24))]
      date = addDays(start, i)
      formattedDate = format(date, "yyyy-MM-dd")
      dates.push(formattedDate)

    Promise.all(dates.map((date) ->
      ParkApi.openAvailable({ start_date: date, end_date: date }).then (data) ->
        if data.length > 0
          return { date, places: data }
        else
          return null
    )).then (results) ->
      filteredResults = results.filter(Boolean) # Remove nulls
      setReservableDates(filteredResults)

  handleReserveClick = (placeId, date) ->
    selectedDate = new Date(Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    ))
    setSelectedDate(selectedDate)
    availablePlacesForDate = openAvailable.filter((place) ->
      days = Object.keys(place.open_periods or {})
      days.some((day) ->
        today = selectedDate
        dayOffset = {
          "sunday": 0, "monday": 1, "tuesday": 2, "wednesday": 3,
          "thursday": 4, "friday": 5, "saturday": 6
        }[day.toLowerCase()]  

        return false unless dayOffset?

        openDate = new Date(today)
        openDate.setDate(today.getDate() + ((dayOffset - today.getDay() + 7) % 7))
        openDate.toISOString().split("T")[0] == selectedDate.toISOString().split("T")[0]
      )
    )
    setSelectedPlaces(availablePlacesForDate)

  loadData = (calendar = null) ->
    calendar = { startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) } unless calendar
    EventApi.filtered(dateParams(calendar)).then (data) ->
      setEvents(data)
    ParkApi.openAvailable().then (data) ->
      setOpenAvailable(data)
    fetchReservableDates()

  updateDateRange = (range) ->
    setSelectedDate(range.selection.startDate)
    setCalendar([range.selection])

  # New handler for FullCalendar date range changes
  handleDatesSet = (info) ->
    newRange =
      startDate: info.start
      endDate: info.end
      key: 'selection'
    setCalendar([newRange])
    setSelectedDate(info.start)

  useEffect(() ->
    loadData(calendar[0])  # Use local state instead of global
  , [])


  calendarEvents = events.flatMap((model) ->
    base = "/app#/community-calendar"
    if model.category == "multiday"
      startDate = new Date("#{model.date}T09:00:00")  # Default start time at 9 AM
      endDate = new Date("#{model.end_date}T18:00:00")  # End time at 6 PM

      return {
        id: model.id
        targetUid: model.targetUid
        title: model.name
        start: startDate.toISOString()
        end: endDate.toISOString()
        url: "#{absolutizePath(base)}/#{model.targetUid}" 
        allDay: true
      }

    else if model.is_recurring and model.occurrences?  # Handle recurring events
      return model.occurrences.map((occurrence) ->
        occurrenceDate = new Date("#{occurrence.date}T#{occurrence.time}:00")
        endTime = new Date("#{occurrence.date}T#{occurrence.close_time}:00")

        return {
          id: occurrence.id
          targetUid: model.targetUid
          title: model.name
          start: occurrenceDate.toISOString()
          end: endTime.toISOString()
          allDay: false
          url: "#{absolutizePath(base)}/#{model.targetUid}"
        }
      )

    else  # Regular single-day events
      startDate = new Date("#{model.date}T#{model.open_time_local}:00")
      endDate = new Date("#{model.date}T#{model.close_time_local}:00")

      return {
        id: model.id
        targetUid: model.targetUid
        title: model.name
        start: startDate.toISOString()
        end: endDate.toISOString()
        url: "#{absolutizePath(base)}/#{model.targetUid}"
        allDay: false
      }
  )

  calendarEvents.push(
    ...reservableDates.map((entry) ->
      {
        id: "reserve-#{entry.date}"
        title: "● Open to Reserve"
        start: entry.date
        allDay: true
      }
    )
  )

  <TopLevelPage className="Calendar" modelId={feedItemId} base="/community-calendar">
    <div className="row calendar-section justify-content-center mt-3">
      <div className="col">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin]}
          initialView="dayGridMonth"
          timeZone= 'local'
          headerToolbar={{
            left: 'title',
            right: 'today prev,next'
          }}
          datesSet={handleDatesSet}
          dateClick={(info) -> setSelectedDate(new Date(info.dateStr))}
          eventClick={(info) ->
            if info.event?.title?.includes("Open to Reserve")
              selectedDate = new Date(info.event.start)
              setSelectedDate(selectedDate)
              formattedDate = selectedDate.toLocaleDateString('en-CA')
              ParkApi.openAvailable({ start_date: formattedDate, end_date: formattedDate }).then (data) ->
                setSelectedPlaces(data)
              handleReserveClick(info.event.extendedProps.placeId, selectedDate)
          }
          events={calendarEvents}
          height="auto"
        />
      </div>
    </div>

    {selectedPlaces.length > 0 &&
      <section>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h1 className="section-heading-lowercase my-3">Available Amenity Reservations</h1>
          </div>
        </div>

        <div className="row">
          {selectedPlaces.map((place, index) ->
            <div key={index} className="col-sm-6 col-lg-4 col-xl-3">
              <ParkCard
                park={place}
                base="/community-calendar"
                selectedDate={if selectedDate instanceof Date then format(selectedDate, "yyyy-MM-dd") else format(new Date(), "yyyy-MM-dd")}
              />
            </div>
          )}
        </div>
      </section>
    }
  </TopLevelPage>

export default withErrorBoundary connected CalendarView
