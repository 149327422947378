import { Spinner, NoResults } from 'shared'
import { Event, Park } from 'api'
import { ParkCard } from 'ui/parks'
import { EventCard } from 'ui/events'

export default function ModelGrid({ models, base, pageTitle }) {
  return (

    <div className="model-grid-container" >
      {
        pageTitle &&
        <div className="row justify-content-center model-grid-heading-container">
          <h1 className="model-grid-heading custom-heading-community">{ pageTitle }</h1>
        </div>
      }
      <div className="row">
        {models == null ? <Spinner /> :
         models.length == 0 ? <NoResults /> :
         models.map(model =>
           <div className="col-sm-6 col-lg-4 col-xl-3" key={model.uid}>
             {
               model instanceof Event ? <EventCard event={model} base={base} /> :
               model instanceof Park ? <ParkCard park={model} base={base} /> : null
             }
           </div>
        )}
      </div>
    </div>
  )
}
