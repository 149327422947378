import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import useClickOutside from "@restart/ui/useClickOutside";
import { SmartButton, SmartButtonLink } from "ui/smart-buttons";
import { AnnouncementApi } from "api";
import _ from 'lodash';
import "./alerts.sass";

const Alerts = () => {
  const [announcements, setAnnouncements] = useState([]);
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  
  useEffect(() => {
    const fetchAnnouncements = async () => {
      const response = await AnnouncementApi.index();
      setAnnouncements(response);
    };

    fetchAnnouncements();
  }, []);

  const toggleDropdown = async () => {
    setOpen((prevOpen) => !prevOpen);

    if (!open) {
      const unreadAnnouncements = announcements.filter((announcement) => !announcement.is_readed);
      await Promise.all(
        unreadAnnouncements.map(async (announcement) => {
          await AnnouncementApi.markAsRead(announcement.id);
        })
      );

      const updatedAnnouncements = await AnnouncementApi.index();
      setAnnouncements(updatedAnnouncements);
    }
  };

  const showIndicator = _.some(announcements, { is_readed: false });

  useClickOutside(dropdownRef, () => setOpen(false));

  return (
    <div
      className="bellIconContainer dropdown"
      onClick={toggleDropdown}
      ref={dropdownRef}
    >
      {showIndicator && <span className="indicator"></span>}
      <FontAwesomeIcon icon={faBell} className="icon" />

      {open && (
        <div
          className="announcements dropdown-menu-custom p-4 shadow-lg dropdown-menu show"
        >
          {announcements.length > 0 ? (
            <>
              <h4>Announcements</h4>
              {announcements.map((announcement) => (
                <div key={announcement.id} className="announcement-item pb-3">
                  <p className="text">{announcement.text}</p>
                  <p className="time">{announcement.created_at}</p>

                  {announcement.new_announcement ? (
                    <div className="home-chat-link pt-3 text-center">
                      <SmartButtonLink
                        text={announcement.target_name}
                        className="admin-purple inverted primary"
                        to="/home/chat"
                      />
                    </div>
                  ) : (
                    announcement.target_arn && (
                      <div className="home-chat-link pt-3 text-center">
                        <SmartButtonLink
                          text={announcement.target_name}
                          className="admin-purple inverted primary"
                          to={`/home/${announcement.target_path}`}
                        />
                      </div>
                    )
                  )}
                </div>
              ))}
            </>
          ) : (
            <p>No new announcements at this time.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Alerts;
