import { Person, PeopleApi, ClubApi } from 'api'
import { TopLevelPage } from 'ui'
import { withErrorBoundary, connected, SearchBar, Formatter } from 'shared'
import { Spinner, NoResults } from 'shared'
import { absolutizePath } from 'shared/util'
import { SmartButton, SmartButtonLink } from 'ui/smart-buttons'
import SmartButtonApi  from 'api/smart-button-api'
import PersonModal from './PersonModal'

class PeopleList extends Component {
  static propTypes = {
    match: PropTypes.object,
    loggedInUser: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.query = PeopleApi.paramsFor('index').q
    this.state = {
      currentPage: 1,
      totalPage: 1,
      selectedUser: props.loggedInUser,
    };
  }

  componentDidMount() {
    this.fetchCurrentUser();
  }

  fetchCurrentUser = async () => {
    try {
      const response = await Backend.get('/users/me');
      this.setState({ selectedUser: response.user });
    } catch (error) {
      console.error('Error fetching current user:', error);
    }
  };

  handleUserClick = (user) => {
    this.setState({ selectedUser: user })
  }

  render() {
    let { id, club_id, args, place_checkins, reserved_event_id } = this.props.match.params
    let { smartButtons, reloadSmartButtons, loggedInUser } = this.props
    let { currentPage, selectedUser } = this.state
    let { totalPage } = this.state

    let base
    if (club_id){
      base = args ? `people::${args}` : `people/club/${club_id}`
    }
    else if(place_checkins){
      base = args ? `people::${args}` : `people/place_checkins/${place_checkins}`
    }
    else if(reserved_event_id){
      base = args ? `people::${args}` : `people/event/${reserved_event_id}`
    }
    else{
      base = args ? `people::${args}` : `people`
    }

    let urlParams = new URLSearchParams(args)
    let userRoleId = urlParams.get('role')
    let club_models = ClubApi.kick('joined')
    let models = PeopleApi.kick('index', { 
                  q: this.query, user_role_id: userRoleId, club_id: club_id, place_checkins: place_checkins,
                  reserved_event_id: reserved_event_id, page: currentPage })
    
    if (models && models.length != 0){
      let lastIndex = models.length - 1
      totalPage = models[lastIndex].totalPages
    }

    return (
      <div>
        <TopLevelPage className="People" modelId={id} base={"/" + base}>
          <div className="entity-smart-buttons smart-buttons">
            {smartButtons &&
              smartButtons.map((smartButton) => (
                <SmartButton
                  {...{ smartButton, reloadSmartButtons, base }}
                  key={smartButton.key}
                  returnPath={application.path}
                />
              ))}
          </div>
        </TopLevelPage>

        <div className="EventModal custom-modal">
          <div className="modal-body">
            <button
              className="close-modal"
              onClick={() => console.log("Close modal")}
            >
              &times;
            </button>
            <div className="left-pane">
              <div className="card event-title-card">
                <div className="col-sm-6 col-lg-6 col-xl-6">
                  <SearchBar
                    onChange={this.queryDidChange}
                    onSubmit={this.queryWouldSubmit}
                    initialQuery={this.query}
                  />
                </div>
              </div>
              <div className="card event-info event-info-new">
                <div>
                  {models == null ? (
                    <Spinner />
                  ) : models.length == 0 ? (
                    <NoResults />
                  ) : (
                    <table className="person-table table">
                      <tbody>
                        {models.map((user) => (
                          <tr
                            key={user.uid}
                            className="person-table-row event-information-detail"
                            onClick={() => this.handleUserClick(user)}
                          >
                            <td className="person-avatar-cell">
                              {user.picture && (
                                <Link
                                  to={`people/${user.uid}`}
                                  className="plain-link"
                                >
                                  <img
                                    src={user.picture}
                                    className="person-avatar rounded-circle"
                                  />
                                </Link>
                              )}
                            </td>
                            <td>
                              {club_id ? (
                                <Link
                                  to={`${club_id}/${user.uid}`}
                                  className="plain-link"
                                >
                                  {user.name}
                                </Link>
                              ) : reserved_event_id ? (
                                <Link
                                  to={`${reserved_event_id}/${user.uid}`}
                                  className="plain-link"
                                >
                                  {user.name}
                                </Link>
                              ) : place_checkins ? (
                                <Link
                                  to={`${place_checkins}/${user.uid}`}
                                  className="plain-link"
                                >
                                  {user.name}
                                </Link>
                              ) : (
                                <div
                                  to={`people/${user.uid}`}
                                  className="plain-link"
                                  style={{ cursor: 'pointer' }}
                                >
                                  {user.name}
                                </div>
                              )}
                            </td>
                            <td>{user.phone}</td>
                            <td>{user.email}</td>
                            <td className="person-followers-cell">
                              {user.result_based_on_settings_identifier}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="pagination-bar">
                  <button
                    disabled={currentPage === 1}
                    onClick={this.handlePreviousPage}
                  >
                    Previous
                  </button>
                  <span>
                    &nbsp; Page {currentPage} of {totalPage} &nbsp;
                  </span>
                  <button
                    disabled={currentPage === totalPage}
                    onClick={this.handleNextPage}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div className="card right-pane right-card">
              {selectedUser && (
                <PersonModal
                  person={selectedUser}
                  base={base}
                  smartButtons={smartButtons}
                  reloadSmartButtons={reloadSmartButtons}
                  showPrivacySettings={selectedUser.id === User.current.id}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  handlePreviousPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage - 1,
    }));
  };

  handleNextPage = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  queryDidChange = query => { this.query = query }
  queryWouldSubmit = () => { this.forceUpdate() }
}

PeopleList.requestSmartButtons = (success) => {
  return SmartButtonApi.forUsers(success)
}

export default SmartButton.containerComponent(null, withErrorBoundary(connected(PeopleList)), { Source: PeopleList })
