import { useState, useEffect } from "react";

const ParkImageSlider = ({ images, fallbackImage }) => {
  const validImages = Array.isArray(images) ? images : [];
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (validImages.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % validImages.length);
      }, 5000);

      return () => clearInterval(interval);
    }
  }, [validImages]);

  return (
    <div className="card park-title-card">
      <img
        className={`park-image ${validImages.length > 1 ? "fade-animation" : ""}`}
        src={validImages.length ? validImages[currentIndex] : fallbackImage}
        alt="Park"
      />
    </div>
  );
};

export default ParkImageSlider;
