import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import Navbar from './navbar'
import Footer from './footer';
import { Routes, UnauthenticatedRoutes } from './routes'
import { OverlayContainer } from 'shared'
import { DownloadsPage, ChangePasswordPage, MarketplaceSignupPage } from 'ui'


export default class Layout extends Component {
  render() {
    let ForcedComponent = checkForcedComponent()

    return (
      <HashRouter>
        {
          ForcedComponent ?
            <ForcedComponent /> :
            App.isSignedIn ?
              <Route render={props => {
                let pathname = props.location.pathname || ''
                return <React.Fragment>
                  { showNavbar() && <Navbar />}
                  <main className='Main'>
                    <Routes />
                    <OverlayContainer />
                  </main>
                  {
                    // showFooter() && <footer className='Footer container'>{application.assets.get('copyright')}</footer>
                    < Footer />
                  }
                </React.Fragment>
              }} />
              :
              <UnauthenticatedRoutes />
        }
      </HashRouter>
    )
  }
}

function showNavbar() {
  return !(application.path.includes('/signup') ||
    application.path.includes('/login') ||
    application.path.includes('/app-downloads') ||
    application.path.includes('/marketplace-signup'))
}

function showFooter() {
  return !(application.path.includes('/signup'))
}

function checkForcedComponent() {
  if (location.pathname == '/download') return DownloadsPage
  return null
}
