import classNames from 'classnames'

export default function Badge({event}) {
  if (!event.badge && !event.multipleBadge) return null
  let bgClass = event.isReserved || event.isEnrolled || event.isParkReservation || event.isWaitlisted ? 'bg-primary' : 'bg-secondary'
  let mbClass = event.isLimited || event.noAvailability ? 'bg-primary' : 'd-none'
  return (
    <div className="badge-container">
      { event.badge &&
        <div className={classNames('badge', bgClass)}>
          {event.badge}
        </div>
      }

      { event.multipleBadge &&
        <div className={classNames('badge', mbClass)}>
          {event.multipleBadge}
        </div>
      }
    </div>
  )
}
