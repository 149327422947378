import { withErrorBoundary, Formatter } from 'shared'
import { absolutizePath } from 'shared/util'
import EventCardBadge     from './HomeEventCardBadge'
import { eventDateTime, eventDateTitle }  from './EventHelper'
import { MissingImage } from 'customizations'

function EventCard({ event, base = 'events' }) {
  return (
    <custom-card class="EventCard">
      <Link to={`${absolutizePath(base)}/${event.targetUid}`} className='plain-link'>
        <div className="card">
          <div className="card-img-container">
            <img className="card-img-top"
              src={event.image_preview_url || MissingImage}
              alt={event.name}
            />
          </div>
          <div className="card-meta">
            <h5 className="card-heading">{event.name}</h5>
            <div className="card-subheading">{event.place_name}</div>
            <time className="card-note" dateTime={event.open_datetime} title={eventDateTitle(event)}>
              {eventDateTime(event)}
            </time>
            <EventCardBadge event={event} />
          </div>
        </div>
      </Link>
    </custom-card>
  )
}

export default withErrorBoundary(EventCard, { className: 'custom-card' })
