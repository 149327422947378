export default class User
  @signIn: (json) ->
    @clearData()
    @storeData(json)

  @signOut: ->
    @clearData()

  @clearData: ->
    delete localStorage.rlAuthToken
    delete localStorage.rlUserId
    delete localStorage.rlUserEmail
    delete localStorage.rlUserDisplayName
    delete localStorage.rlUserPicture
    delete localStorage.rlHasCard
    delete localStorage.rlIsGuest
    delete localStorage.rlGuestPassId
    delete localStorage.rlGuestExperienceName
    delete localStorage.rlGuestExperienceId
    delete localStorage.rlMustChangePassword
    delete localStorage.rlHidePaymentButton
    delete localStorage.logoUrl
    delete localStorage.fobIds
    delete localStorage.homeFeedBanner
    delete localStorage.homeFeedHeader
    delete localStorage.homeFeedFooter
    delete localStorage.homeFeedFacebook
    delete localStorage.homeFeedInstagram
    delete localStorage.homeFeedTwitter
    delete localStorage.homeFeedYoutube
    delete localStorage.homeFeedPrivacyPolicy
    delete localStorage.homeFeedHeaderColour
    delete localStorage.homeFeedHighLightColour
    delete localStorage.homeFeedSubText
    delete localStorage.homeFeedSubTextColour

  @storeData: (json) ->
    console.log('StoreData', json)
    localStorage.rlAuthToken = json.token
    localStorage.rlUserId = json.id if json.id
    localStorage.rlUserEmail = json.email
    localStorage.rlUserDisplayName = json.first_name || json.guest_experience_name
    localStorage.rlUserPicture = json.picture if json.picture
    localStorage.rlHasCard = json.has_payment_source
    localStorage.rlIsGuest = json.is_guest
    localStorage.rlGuestPassId = json.guest_pass_id if json.guest_pass_id
    localStorage.rlGuestExperienceName = json.guest_experience_name if json.guest_experience_name
    localStorage.rlGuestExperienceId = json.guest_experience_id if json.guest_experience_id
    localStorage.rlMustChangePassword = json.must_change_password if json.must_change_password
    localStorage.rlHidePaymentButton = json.hide_payment_button if json.hide_payment_button
    localStorage.logoUrl = json.logo_url
    localStorage.fobIds = json.fob_ids
    localStorage.homeFeedBanner = json.home_feed_banner
    localStorage.homeFeedHeader = json.home_feed_header
    localStorage.homeFeedFooter = json.home_feed_footer
    localStorage.homeFeedFacebook = json.home_feed_facebook
    localStorage.homeFeedInstagram = json.home_feed_instagram
    localStorage.homeFeedTwitter = json.home_feed_twitter
    localStorage.homeFeedYoutube = json.home_feed_youtube
    localStorage.homeFeedPrivacyPolicy = json.home_feed_privacy_policy
    localStorage.homeFeedHeaderColour = json.home_feed_header_colour
    localStorage.homeFeedHighLightColour = json.home_feed_highlight_colour
    localStorage.homeFeedSubText = json.home_feed_sub_text
    localStorage.homeFeedSubTextColour = json.home_feed_sub_text_colour

  @guestExperienceSignIn: =>
    result = await Backend.post("/auth/guest")
    @signIn(result.user)
    url = '/app'
    if AppData.settings.embed == true
      url = '/app?embed=true'
    window.location = url

  @refresh: ->
    result = await Backend.get("/profile")
    token = localStorage.rlAuthToken
    @clearData()
    @storeData({...result.user, token})
    application.state.trigger("user:update")


  @cprop 'current',    -> if localStorage.rlAuthToken then new User else null
  @cprop 'signedIn',   -> @current?
  @cprop 'regular',    -> @current?.regular
  @cprop 'guest',      -> @current?.guest
  @cprop 'isGuestExperience', -> @current?.isGuestExperience
  @cprop 'isGuestPass',       -> @current?.hasGuestPass
  @cprop 'homeFeedBanner',    -> localStorage.homeFeedBanner != 'null' && localStorage.homeFeedBanner
  @cprop 'homeFeedHeader',    -> localStorage.homeFeedHeader != 'null' && localStorage.homeFeedHeader
  @cprop 'homeFeedFooter',    -> localStorage.homeFeedFooter != 'null' && localStorage.homeFeedFooter
  @cprop 'homeFeedFacebook',    -> localStorage.homeFeedFacebook != 'null' && localStorage.homeFeedFacebook
  @cprop 'homeFeedInstagram',    -> localStorage.homeFeedInstagram != 'null' && localStorage.homeFeedInstagram
  @cprop 'homeFeedTwitter',    -> localStorage.homeFeedTwitter != 'null' && localStorage.homeFeedTwitter
  @cprop 'homeFeedYoutube',    -> localStorage.homeFeedYoutube != 'null' && localStorage.homeFeedYoutube
  @cprop 'homeFeedPrivacyPolicy',    -> localStorage.homeFeedPrivacyPolicy != 'null' && localStorage.homeFeedPrivacyPolicy
  @cprop 'homeFeedHeaderColour',    -> localStorage.homeFeedHeaderColour != 'null' && localStorage.homeFeedHeaderColour
  @cprop 'homeFeedHighLightColour',    -> localStorage.homeFeedHighLightColour != 'null' && localStorage.homeFeedHighLightColour
  @cprop 'homeFeedSubText',    -> localStorage.homeFeedSubText != 'null' && localStorage.homeFeedSubText
  @cprop 'homeFeedSubTextColour',    -> localStorage.homeFeedSubTextColour != 'null' && localStorage.homeFeedSubTextColour
  @prop 'id',                 -> localStorage.rlUserId && Number(localStorage.rlUserId)
  @prop 'name',               -> localStorage.rlUserDisplayName
  @prop 'logoUrl',            -> localStorage.logoUrl
  @prop 'email',              -> localStorage.rlUserEmail
  @prop 'picture',            -> localStorage.rlUserPicture != 'null' && localStorage.rlUserPicture
  @prop 'authToken',          -> localStorage.rlAuthToken
  @prop 'hasCard',            -> localStorage.rlHasCard == 'true'
  @prop 'guest',              -> localStorage.rlIsGuest == 'true'
  @prop 'mustChangePassword', -> localStorage.rlMustChangePassword == 'true'
  @prop 'hidePaymentButton', -> localStorage.rlHidePaymentButton == 'true'
  @prop 'regular',            -> !@guest
  @prop 'hasGuestPass',       -> localStorage.rlGuestPassId isnt undefined
  @prop 'guestPassId',        -> localStorage.rlGuestPassId
  @prop 'isGuestExperience',  -> localStorage.rlGuestExperienceId isnt undefined

  @resetPasswordChangeFlag = ->
    delete localStorage.rlMustChangePassword

  signOut: -> User.signOut()
