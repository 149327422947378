const LogoImage       = requireCustomAsset('logo', 'png')
const LogoImageRight  = requireCustomAsset('logo-new', 'png')
const SpinnerPrimary  = requireCustomOrDefaultAsset('spinner-primary', 'gif')
const SpinnerTertiary = requireCustomOrDefaultAsset('spinner-tertiary', 'gif')
const ChatIcon        = requireCustomOrDefaultAsset('chat-bubble', 'png')
const MissingImage    = requireCustomAssetWithFallback('missing', 'back', 'jpg', 'jpg')

export {
  MissingImage, SpinnerPrimary, SpinnerTertiary, LogoImage, LogoImageRight, ChatIcon
}
